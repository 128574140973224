<template>
  <div class="block">
    <table class="table is-fullwidth is-striped is-hidden-mobile">
      <tbody>
        <tr>
          <th>First Name</th>
          <td colspan="3">{{ info.firstName }}</td>
          <th>Last Name</th>
          <td colspan="3">{{ info.lastName }}</td>
        </tr>
        <tr>
          <th>Address</th>
          <td colspan="7">{{ info.billingAddress }}</td>
        </tr>
        <tr>
          <th>City</th>
          <td col="2">{{ info.city }}</td>
          <th>State</th>
          <td col="2">{{ info.state }}</td>
          <th>Zip</th>
          <td>{{ info.zipCode }}</td>
        </tr>
        <tr v-if="info.paymentOption == '0' || info.paymentOption == '2'">
          <th>Type</th>
          <td>{{ info.paymentOption | formatOption }}</td>
          <th>CC Num</th>
          <td>{{ maskedCCNumber }}</td>
          <th>Exp</th>
          <td>{{ info.ccExpDateMonth }}/{{ info.ccExpDateYear }}</td>
          <th>CVV</th>
          <td>{{ formattedCVV }}</td>
        </tr>
        <tr v-else>
          <th>Type</th>
          <td>{{ info.paymentOption | formatOption }}</td>
          <th>Routing</th>
          <td>{{ info.routingNumber }}</td>
          <th>Account</th>
          <td>{{ info.bankAccountNumber }}</td>
          <th>&nbsp;</th>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <table class="table is-fullwidth is-striped is-hidden-tablet">
      <tbody>
        <tr>
          <th>First Name</th>
          <td>{{ info.firstName }}</td>
        </tr>

        <tr>
          <th>Last Name</th>
          <td>{{ info.lastName }}</td>
        </tr>
        <tr>
          <th>Address</th>
          <td>{{ info.billingAddress }}</td>
        </tr>
        <tr>
          <th>City</th>
          <td>{{ info.city }}</td>
        </tr>
        <tr>
          <th>State</th>
          <td>{{ info.state }}</td>
        </tr>
        <tr>
          <th>Zip</th>
          <td>{{ info.zipCode }}</td>
        </tr>
        <tr v-if="info.paymentOption == '0' || info.paymentOption == '1'">
          <th>Type</th>
          <td>{{ info.paymentOption | formatOption }}</td>
        </tr>
        <tr v-if="info.paymentOption == '0' || info.paymentOption == '1'">
          <th>CC Num</th>
          <td>{{ maskedCCNumber }}</td>
        </tr>
        <tr v-if="info.paymentOption == '0' || info.paymentOption == '1'">
          <th>Exp</th>
          <td>{{ info.ccExpDateMonth }}/{{ info.ccExpDateYear }}</td>
        </tr>

        <tr v-if="info.paymentOption == '0' || info.paymentOption == '1'">
          <th>CVV</th>
          <td>{{ formattedCVV }}</td>
        </tr>
        <tr v-if="!(info.paymentOption == '0' || info.paymentOption == '1')">
          <th>Type</th>
          <td>{{ info.paymentOption | formatOption }}</td>
        </tr>
        <tr v-if="!(info.paymentOption == '0' || info.paymentOption == '1')">
          <th>Routing</th>
          <td>{{ info.routingNumber }}</td>
        </tr>
        <tr v-if="!(info.paymentOption == '0' || info.paymentOption == '1')">
          <th>Account</th>
          <td>{{ info.bankAccountNumber }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["info"],
  computed: {
    maskedCCNumber() {
      if (this.info.ccNumber) {
        return (
          "************" +
          this.info.ccNumber.substring(
            this.info.ccNumber.length - 4,
            this.info.ccNumber.length
          )
        );
      }
      return "";
    },
    maskedAcountNumber() {
      if (this.info.ccNumber) {
        return (
          "****" +
          this.info.bankAccountNumber.substring(
            this.info.bankAccountNumber.length - 4,
            this.info.bankAccountNumber.length
          )
        );
      }
      return "";
    },
    formattedCVV() {
      if (this.info.cvv) {
        return "Provided";
      }
      return "";
    }
  },
  filters: {
    formatOption(val) {
      if (val == "0" || val == "2") {
        return "CREDIT";
      } else {
        return "CHECK";
      }
    }
  }
};
</script>
