<template>
  <base-layout :loadingFlag="$store.state.loading">
    <breadcrumbs :links="links" />

    <h1 class="title">Final Review</h1>
    <p class="content">
      Please verify the following payment information and scheduled then press
      the Finalize button.
    </p>
    <div class="buttons">
      <button class="button is-success" @click="finalize">Finalize</button>
      <button class="button is-danger" @click="deletePendingPPA">Cancel</button>
    </div>
    <div class="level">
      <div class="level-left">
        <h2 class="subtitle has-text-link level-item">Payment Information</h2>
      </div>
      <div class="level-right">
        <div class="buttons level-item">
          <router-link
            tag="button"
            class="button is-warning"
            :to="{ name: 'overTime.payment' }"
            >Change</router-link
          >
        </div>
      </div>
    </div>
    <payment-info :info="$store.state.arr.paymentInfo" />

    <div class="level">
      <div class="level-left">
        <h2 class="subtitle has-text-link level-item">Schedule</h2>
      </div>
      <div class="level-right">
        <div class="buttons level-item">
          <router-link
            tag="button"
            class="button is-warning"
            :to="{ name: 'overTime' }"
            >Redo Arrangement</router-link
          >
        </div>
      </div>
    </div>
    <schedule 
      :arr="$store.state.arr.arrangement" 
      :fee="feeResult.amount" 
      :include-penalty="instanceConfig.includePenalty"
      :include-interest="includeInterest(false)" />
    <fee-message />
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import Schedule from "@/components/arrangement/Schedule.vue";
import PaymentInfo from "@/components/payment/PaymentInfo.vue";
import executeArrangement from "@/activities/ExecuteArrangement";
import store from "@/store/index";
import FeeMessage from "@/views/payment/overtime/FeeMessage";

function unloadListener(event) {
  event.preventDefault();
  event.returnValue = "";
}

export default {
  components: { BaseLayout, Breadcrumbs, Schedule, PaymentInfo, FeeMessage },
  data() {
    return {
      links: []
    };
  },
  computed: {
    ...mapState(["instanceConfig"]),
    ...mapState("pay", ["feeResult"]),
    ...mapGetters("arr", ["includeInterest"])
  },
  created() {
    this.links = [
      { label: "Home", icon: "home", to: { name: "home" } },
      {
        label: "Make Payment",
        icon: "dollar-sign",
        to: { name: "makePayment", params: { id: this.$route.params.id } }
      },
      {
        label: this.instanceConfig.overTimeTitle,
        icon: "calendar",
        to: { name: "overTime", params: { id: this.$route.params.id } }
      },
      {
        label: "Settlement Acceptance",
        icon: "check",
        to: {
          name: "amortizationSchedule",
          params: { id: this.$route.params.id }
        }
      },
      {
        label: "Payment",
        icon: null,
        to: { name: "overTime.payment", params: { id: this.$route.params.id } }
      },
      {
        label: "RegE",
        icon: null,
        to: { name: "overTime.regE", params: { id: this.$route.params.id } }
      },
      {
        label: "Review",
        icon: null,
        to: {
          name: "overTime.finalReview",
          params: { id: this.$route.params.id }
        },
        isActive: true
      }
    ];
  },
  beforeRouteEnter(to, from, next) {
    if (
      store.state.instanceConfig &&
      Object.keys(store.state.instanceConfig).length === 0 &&
      store.state.instanceConfig.constructor === Object
    ) {
      next({
        name: "overTime",
        params: { instanceId: to.params.instanceId, id: to.params.id }
      });
    } else {
      window.addEventListener("beforeunload", unloadListener);
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  },
  methods: {
    ...mapActions("arr", ["deleteArrangement"]),
    async finalize() {
      window.scrollTo(0, 0);
      this.$store.commit("SET_LOADING", true);
      try {
        await executeArrangement({
          instanceId: this.$route.params.instanceId,
          refNum: this.$route.params.id,
          demIdtyNo: this.$store.getters["accounts/fullAccount"](
            this.$route.params.id
          ).DEMIDTYNO.value
        });
        this.$router.push({ name: "overTime.thank" });
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    },
    async deletePendingPPA() {
      this.$store.commit("SET_LOADING", true);
      try {
        await this.deleteArrangement();
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }

      this.$router.push({ name: "overTime" });
    }
  }
};
</script>
