<template>
  <div>
    <table class="table is-fullwidth is-striped is-hidden-mobile" data-testid="non-mobile-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Scheduled</th>
          <th>Principal</th>
          <th v-if="includeInterest">Interest</th>
          <th v-if="includePenalty">Penalty</th>
          <th>Amount</th>
          <th>Balance</th>
          <th v-if="fee">Fee *</th>
          <th v-if="fee">Total Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ar in arr" :key="ar.paymentNo">
          <td>{{ ar.paymentNo }}</td>
          <td>{{ ar.scheduledDate | formatDate }}</td>
          <td>{{ ar.principalAmount | formatCurrency }}</td>
          <td v-if="includeInterest">{{ ar.interestAmount | formatCurrency }}</td>
          <td v-if="includePenalty">{{ ar.penaltyAmount | formatCurrency }}</td>
          <td>{{ ar.paymentAmount | formatCurrency }}</td>
          <td>{{ ar.balance | formatCurrency }}</td>
          <td v-if="fee">{{ fee | formatCurrency }}</td>
          <td v-if="fee">{{ (ar.paymentAmount + fee) | formatCurrency }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table is-fullwidth is-hidden-tablet" data-testid="mobile-table">
      <tbody v-for="ar in arr" :key="ar.paymentNo" class="record">
        <tr>
          <th>#</th>
          <td>{{ ar.paymentNo }}</td>
        </tr>
        <tr>
          <th>Scheduled</th>
          <td>{{ ar.scheduledDate | formatDate }}</td>
        </tr>
        <tr>
          <th>Principal</th>
          <td>{{ ar.principalAmount | formatCurrency }}</td>
        </tr>
        <tr v-if="includeInterest">
          <th>Interest</th>
          <td>{{ ar.interestAmount | formatCurrency }}</td>
        </tr>
        <tr v-if="includePenalty">
          <th>Penalty</th>
          <td>{{ ar.penaltyAmount | formatCurrency }}</td>
        </tr>
        <tr>
          <th>Amount</th>
          <td>{{ ar.paymentAmount | formatCurrency }}</td>
        </tr>
        <tr>
          <th>Balance</th>
          <td>{{ ar.balance | formatCurrency }}</td>
        </tr>
        <tr v-if="fee">
          <th>Fee *</th>
          <td>{{ fee | formatCurrency }}</td>
        </tr>
        <tr v-if="fee">
          <th>Total Amount</th>
          <td>{{ (ar.paymentAmount + fee) | formatCurrency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["arr", "fee","includePenalty","includeInterest"]
};
</script>
<style lang="scss">
.record:nth-child(2n) {
  background-color: #fff;
}
</style>
