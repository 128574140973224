import store from "../store/index";

export default async function(payload) {
  if (store.state.rege.rege != null && store.state.rege.rege.length > 0) {
    await store.dispatch("rege/saveRegEAcceptance", payload.demIdtyNo);
  }
  await store.dispatch("arr/updateArrangement", {
    instanceId: payload.instanceId,
    refNum: payload.refNum,
    demIdtyNo: payload.demIdtyNo,
    fee: store.state.pay.feeResult.amount
  });
}
